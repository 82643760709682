import "./FormControl.css";
import classNames from 'classnames';
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { validateRequired } from "../../lib/Validators.js";
import { FormKeyContext } from "./Form.js";

let nextID = 0;
const createFormControlID = () => "form-input-id-" + (nextID++);

const FormControl = (FormControlInput, options = {}) => {
    options = Object.assign({
        valueToInput: (value) => value,
        className: "",
    }, options);

    function FormControlWrapper({
        className,
        icon,
        initialValue = "",       // Default parameter
        value: forceValue,
        label,
        name,
        onChange = () => null,   // Default parameter
        validator = () => null,  // Default parameter
        required,
        uncontrolled,
        noMargin,
        children,
        checkPresence,
        placeholder = "",        // Default parameter
        ...otherProps
    }) {
        const id = useRef(createFormControlID()).current;
        const [error, setError] = useState("");
        const [success, setSuccess] = useState("");
        const [value, setValue] = useState(options.valueToInput(initialValue));
        const formKey = useContext(FormKeyContext);

        const clearFeedback = () => { setSuccess(""); setError(""); };

        useEffect(() => {
            if (uncontrolled || forceValue !== undefined)
                return;
            if (!formKey)
                throw Error("Controlled form element has no form key! Please add the uncontrolled prop or use a Form component");
            if (!name)
                throw Error("Controlled form element has no name! Please add the uncontrolled prop or use a Form component");
            const controller = {
                id: id,
                getName: () => name,
                getValue: () => value,
                setSuccess: (message) => { clearFeedback(); setSuccess(message); },
                setError: (message) => { clearFeedback(); setError(message); },
                clearFeedback: clearFeedback,
                clear: () => setValue(""),
                validate: () => {
                    const usedValidator = required ? validateRequired : validator;
                    let errorMsg = usedValidator(value);
                    clearFeedback();
                    if (errorMsg) {
                        setError(errorMsg);
                        return false;
                    }
                    return true;
                },
                setValue: (value) => {
                    let newValue = options.valueToInput(value);
                    if (newValue === undefined || newValue === null)
                        console.error("Warning, trying to set value of undefined to input: " + name)
                    else {
                        setValue(newValue)
                        onChange(value);
                    }

                    if (checkPresence) {
                        if (!otherProps.values.includes(value)) {
                            setValue('Outra:')
                            onChange('Outra:');
                        }
                    }
                }
            }
            formKey.addInput(controller);
            return () => { formKey.removeInput(controller) };
        }, [id, name, formKey, validator, value, onChange, uncontrolled, required, forceValue, checkPresence, otherProps.values])

        const handleChange = (value) => {
            clearFeedback();
            setValue(value);
            onChange(value);
        }

        let classes = classNames(className, options.className, {
            "form-control": true,
            "form-control-with-margin": !noMargin,
            "form-control-error": error !== "",
            "form-control-success": success !== ""
        });

        return (
            <div className={classes}>
                {label ? (<label htmlFor={id} className="form-label">{label}</label>) : <></>}
                <div className="form-control-container">
                    <FormControlInput
                        id={id}
                        onChange={handleChange}
                        value={forceValue === undefined ? value : forceValue}
                        name={name}
                        placeholder={placeholder}
                        {...otherProps}
                    />
                    <div className="form-control-icon">{icon}</div>
                </div>
                <div>{children}</div>
                <div className="form-error-message">{error}</div>
                <div className="form-success">{success}</div>
            </div>
        );
    }

    FormControlWrapper.propTypes = {
        name: PropTypes.string,
        onChange: PropTypes.func,
        validator: PropTypes.func,
        placeholder: PropTypes.string,
    };

    return FormControlWrapper;
}

export default FormControl;

export { createFormControlID }

import React, { useState } from "react";
import { Link } from "react-router-dom";
import bodyimg from "../assets/imagem_base_prescrição.png";
import ShadowCard from "../components/card/ShadowCard.js";
import styles from "./ExerciseItems.module.css";

const ExerciseItems = (props) => {
  const [image, setImage] = useState(false);
  const [customImage, setCustomImage] = useState();

  const hover = (url) => {
    setImage(true);
    setCustomImage(url);
  };

  const left = () => {
    setImage(false);
  };

  const renderElement = (index, resource) => {
    if (index % 2 === 0) {
      return (
        <Link
          key={resource.id + "-" + index}
          to={`/resources/exercises/${resource.id}`}
        >
          <ShadowCard
            className={styles.resource}
            onMouseOver={() => hover(resource.Bodypart.image)}
            onMouseLeave={left}
          >
            <h3 className={styles.title}>{resource.title}</h3>
            <small className={styles.description}>{resource.description}</small>
          </ShadowCard>
        </Link>
      );
    } else return null;
  };

  const renderElement2 = (index, resource) => {
    if (index % 2 !== 0) {
      return (
        <Link
          key={resource.id + "-" + index}
          to={`/resources/exercises/${resource.id}`}
        >
          <ShadowCard
            className={styles.resource}
            onMouseOver={() => hover(resource.Bodypart.image)}
            onMouseLeave={left}
          >
            <h3 className={styles.title}>{resource.title}</h3>
            <small className={styles.description}>{resource.description}</small>
          </ShadowCard>
        </Link>
      );
    } else return null;
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.left}>
        {props.resources.map((resource, index) =>
          renderElement(index, resource)
        )}
      </div>
      <div className={styles.left2} id="page">
        {image ? (
          <img
            loading="lazy"
            className={styles.imagem}
            src={customImage}
            alt="Imagem da parte do corpo"
          ></img>
        ) : (
          <img
            loading="lazy"
            className={styles.imagem}
            src={bodyimg}
            alt="Imagem da parte do corpo"
          ></img>
        )}
      </div>
      <div className={styles.left}>
        {props.resources.map((resource, index) =>
          renderElement2(index, resource)
        )}
      </div>
    </section>
  );
};

export default ExerciseItems;

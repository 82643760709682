import React from "react";
import { CookieConsent } from "react-cookie-consent";

import RegularLink from "../link/RegularLink.js";

const CookieBanner = () => (
  <CookieConsent
    cookieName="has-accepted-cookies"
    acceptOnScroll={true}
    sameSite={"lax"}
    buttonText={"Aceitar cookies"}
    buttonStyle={{
      backgroundColor: "var(--primary)",
      color: "white",
      padding: "1em",
    }}
  >
    Nós usamos cookies para garantir uma melhor experiência. Continua a
    navegação no site se concordas, ou dá uma olhada à nossa{" "}
    <RegularLink to="/privacy">política de privacidade</RegularLink> para mais
    informações.
  </CookieConsent>
);

export default CookieBanner;

import "./FormInput.css";
import "./FormSelect.css";
import {ArrowDownward as ArrowDownIcon} from "@mui/icons-material";
import classNames from 'classnames';
import PropTypes from "prop-types";
import React from "react";
import FormControl from "./FormControl.js";


const FormSelect = FormControl(
  ({ className, onChange, placeholder, values, labels, name, ...otherProps }) => {
    return (
      <select
        className={classNames("form-input", className)}
        onBlur={(event) => onChange(event.target.value)}
        onChange={(event) => onChange(event.target.value)}
        {...otherProps}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {values.map((option, index) => {
          if (name !== 'countryCode') {
            return (
              <option
                key={option}
                value={option}
                label={labels ? labels[index] : option}
              >
                {option}
              </option>
            );
          } else {
            return (
              <option
                key={option.code}
                value={option.dial_code}
                label={labels ? labels[index] : option.emoji + option.dial_code}
              >
                {option.name}
              </option>
            );
          }
        })}
      </select>
    );
  }
);
export default FormSelect;

FormSelect.propTypes = Object.assign({}, FormSelect.propTypes, {
  values: PropTypes.array.isRequired,
  labels: PropTypes.array,
});

FormSelect.defaultProps = Object.assign({}, FormSelect.defaultProps, {
  icon: <ArrowDownIcon />,
});

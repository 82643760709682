import axios from "axios";
import {useCallback, useEffect, useState} from "react";

import AxiosFileSender from "../lib/AxiosFileSender.js";

function useAPIItem(baseURL, initialState = {}) {
    const [item, setItem] = useState(initialState);

    const editItem = useCallback((body) => {
        return AxiosFileSender.put(baseURL, body).then(res => {
            setItem(res.data)
            return res.data;
        }).catch(console.error)
    }, [baseURL])
    
    const deleteItem = useCallback(() => {
        return axios.delete(baseURL).catch(console.error);
    }, [baseURL])

    useEffect(() => {
        axios.get(baseURL).then(res => setItem(res.data)).catch(console.error)
    }, [baseURL]);

    return [item, editItem, deleteItem]
}

export default useAPIItem;

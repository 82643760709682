import axios from "axios";

import AxiosFileSender from "../lib/AxiosFileSender.js";
import useAPIItem from "./useAPIItem.js";
import useAPIList from "./useAPIList.js";

export function sponsorAsArticle(sponsor) {
    return {
        id: sponsor.id,
        title: sponsor.name,
        thumbnail: sponsor.logo,
    }
}

export function standAsArticle(stand) {
    return {
        id: stand.id,
        title: stand.name,
        thumbnail: stand.image,
    }
}

// --

export function useStand(id) {
    return useAPIItem(`/api/sponsors/stands/${id}`, {
        image: "/",
        name: "Loading...",
        contents: {},
        Sponsor: {}
    });
}

export function useSponsors() {
    return useAPIList("/api/sponsors");
}

export function useStands(sponsorId) {
    return useAPIList(`/api/sponsors/${sponsorId}/stands/`, '/api/sponsors/stands/');
}


// ---

export function fetchSponsors() {
    return axios.get(`/api/sponsors`).then(res => res.data)
}

export function addSponsor(body) {
    return AxiosFileSender.post("/api/sponsors", body).then(res => res.data)
}

export function fetchSponsor(id) {
    return axios.get(`/api/sponsors/${id}`).then(res => res.data)
}

export function updateSponsor(id, body) {
    return AxiosFileSender.put(`/api/sponsors/${id}`, body).then(res => res.data)
}

export function deleteSponsor(id) {
    return axios.delete(`/api/sponsors/${id}`).then(res => res.data)
}

// ---
export function useStandAssets(standId) {
    return [
        useAPIList(`/api/sponsors/stands/${standId}/articles`, '/api/articles'), // articles
        useAPIList(`/api/sponsors/stands/${standId}/documents`, '/api/documents'), // documents
        useAPIList(`/api/sponsors/stands/${standId}/infos`, '/api/infos'), // infos
        useAPIList(`/api/sponsors/stands/${standId}/links`, '/api/sponsors/stands/links'), // links
        useAPIList(`/api/sponsors/stands/${standId}/standarticles`, '/api/sponsors/stands/standarticles'), // articles
        useAPIList(`/api/sponsors/stands/${standId}/studies`, '/api/sponsors/stands/studies'), // studies
        useAPIList(`/api/sponsors/stands/${standId}/videos`, '/api/videos'), // videos
    ]
}

export function fetchStands(sponsorId) {
    return axios.get(`/api/sponsors/${sponsorId}/stands/`).then(res => res.data)
}

export function addStand(sponsorId, body) {
    return AxiosFileSender.post(`/api/sponsors/${sponsorId}/stands/`, body).then(res => res.data)
}

export function fetchStand(id) {
    return axios.get(`/api/sponsors/stands/${id}`).then(res => res.data)
}

export function updateStand(id, body) {
    return AxiosFileSender.put(`/api/sponsors/stands/${id}`, body).then(res => res.data)
}

export function deleteStand(id) {
    return axios.delete(`/api/sponsors/stands/${id}`).then(res => res.data)
}


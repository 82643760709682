import './CustomScrollBar.css'

import classNames from 'classnames';
import React from "react";


const CustomScrollbar = ({ className, children, ...otherProps}) => (
    <div className={classNames("custom-scrollbar", className)} {...otherProps}>
        {children}
    </div>
)


export default CustomScrollbar;
import {
  StarBorder as StarBorderIcon,
  Star as StarIcon,
} from "@mui/icons-material";

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  favoriteResource,
  unfavoriteResource,
} from "../../../api/ResourceAPI.js";
import useBool from "../../../hooks/useBool.js";
import cleanForURL from "../../../lib/CleanForURL.js";
import { startBrowserDownload } from "../../../lib/FileDownloader.js";
import ButtonPrimary from "../../buttons/ButtonPrimary.js";
import ShadowCard from "../../card/ShadowCard.js";
import ImageFluid from "../../image/ImageFluid.js";
import styles from "./ResourceList.module.css";

const ResourceList = ({ resources, resourceType }) => {
  // Guidelines?
  return (
    <section className={styles.resources}>
      {resources.map((resource, index) => (
        <ResourceListItem
          key={resource.id + "-" + index}
          resource={resource}
          resourceType={resourceType}
        />
      ))}
    </section>
  );
};

ResourceList.propTypes = {
  resources: PropTypes.array.isRequired,
  resourceType: PropTypes.string.isRequired,
};

const ResourceListItem = ({ resource, resourceType }) => {
  const navigate = useNavigate();

  const [isFavorite, favorite, unfavorite] = useBool(resource.favorite);
  const [stand] = useState(); // wont use for now

  // useEffect(() => {
  //     if (resource.stand_id)
  //         fetchStand(resource.stand_id).then(setStand);
  // }, [resource.stand_id])

  const toggleFavorite = (event) => {
    event.stopPropagation();
    if (isFavorite) {
      unfavoriteResource(resource.id).catch(console.error);
      unfavorite();
    } else {
      favoriteResource(resource.id).catch(console.error);
      favorite();
    }
  };

  const openResource = () =>
    navigate(
      `/resources/${resourceType}/${cleanForURL(resource.title)}/${
        resource.id
      }`
    );

  return (
    <ShadowCard className={styles.resource} onClick={openResource}>
      <header className={styles.header}>
        <small className={styles.description}>
          {(resource.Guideline || {}).year}
        </small>{" "}
        {/* Only guidelines have year */}
        <h3 className={styles.title}>{resource.title}</h3>
        <small className={styles.description}>{resource.description}</small>
        {resourceType === "guidelines" ? (
          <DownloadGuidelineButton resourceId={resource.id} />
        ) : (
          <></>
        )}
      </header>

      {stand && (
        <ImageFluid
          className={styles.sponsor}
          src={stand.Sponsor.logo}
          alt="Sponsor Logo"
        />
      )}

      {isFavorite ? (
        <StarIcon
          className={classNames(styles.favorite, styles.selected)}
          onClick={toggleFavorite}
        />
      ) : (
        <StarBorderIcon className={styles.favorite} onClick={toggleFavorite} />
      )}
    </ShadowCard>
  );
};

const DownloadGuidelineButton = (props) => (
  <ButtonPrimary
    onClick={(event) => {
      event.stopPropagation();
      startBrowserDownload(
        `/api/resources/guidelines/${props.resourceId}/download`
      );
    }}
    className={styles.downloadButton}
  >
    Download
  </ButtonPrimary>
);

export default ResourceList;

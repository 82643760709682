import { Container } from "@mui/material";
import { CircularProgress as CircularProgressIndicator } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const sizeToNum = {
  small: 20,
  medium: 40,
  large: 60,
};

const CircularProgress = ({
  size = "medium",
  color = "var(--primary)",
  style = {},
  children,
  ...otherProps
}) => {
  const containerStyle = Object.assign(
    {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    style
  );

  const indicatorStyle = {
    color: color,
    margin: style.margin ? style.margin : "0 1em",
  };

  return (
    <Container style={containerStyle}>
      <CircularProgressIndicator
        size={sizeToNum[size]}
        style={indicatorStyle}
        {...otherProps}
      />
      {children}
    </Container>
  );
};

CircularProgress.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizeToNum)),
};

export default CircularProgress;

import { Container } from "@mui/material";
import React from "react";

import CircularProgress from "./CircularProgress.js";

const LoadingContainer = () => (
  <Container style={{ margin: "1em auto" }}>
    <CircularProgress />
  </Container>
);

export default LoadingContainer;

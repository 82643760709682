import React from "react";
import styles from "./Figure.module.css";

function Figure(props) {
  return (
    <figure className={styles.simplefigure}>
      <img loading="lazy" src={props.src} alt="Imagem"></img>
    </figure>
  );
}

export default Figure;

import React from "react";

import ButtonBase from "./ButtonBase.js";

const ButtonPrimary = ({ style, ...otherProps}) => {
    const styles = Object.assign({
        "--hover-color": "var(--primary-light)",
        backgroundColor: "var(--primary)",
        color: "white",

        borderRadius: "6px",
        padding: "0.25em 1.5em",
        
    }, style);

    return <ButtonBase style={styles} {...otherProps}/>
}

export default ButtonPrimary;


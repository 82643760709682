import './AuthLayout.css'

import PropTypes from "prop-types";
import React from "react";

import Brand from "../components/adhara/brand/Brand.js";
import NoLoggedUser from "../components/auth/NoLoggedUser.js";
import FooterContacts from "../components/navigation/FooterContacts.js";
import AppLayout from "./AppLayout.js";

const AuthLayout = (props) => (
    <AppLayout title={props.title} description="Cria uma conta ou faz login em Adhara Academy. Obtém acesso a todos os cursos que temos disponíveis.">
        <NoLoggedUser>
            <section className="auth-section">
                <Brand size="large" center/>

                {props.children}
            </section>
            <FooterContacts/>
        </NoLoggedUser>
    </AppLayout>
)

AuthLayout.propTypes = {
    title: PropTypes.string.isRequired
}

export default AuthLayout;
import React from "react";
import { useNavigate } from "react-router-dom";

import useQuery from "../../../hooks/useQuery.js";
import CustomScrollbar from "../../scroll/CustomScrollBar.js";
import LetterList from "../../search/LetterList.js";
import SearchBar from "../../search/SearchBar.js";
import styles from "./ResourceSearch.module.css";

const ResourceSearch = (props) => {
  const navigate = useNavigate();

  const baseRoute = `/resources/${props.resourceType}`;
  const { query: search, letter: currentLetter } = useQuery();

  const onSearch = (query) => {
    navigate(query ? `${baseRoute}?query=${query}` : baseRoute);
  };
  const onLetterSearch = (letter) => {
    navigate(
      letter && letter !== currentLetter
        ? `${baseRoute}?letter=${letter}`
        : baseRoute
    );
  };

  return (
    <CustomScrollbar>
      <div className={styles.container}>
        <LetterList onSearch={onLetterSearch} selected={currentLetter} />
        <div className={styles.search}>
          <SearchBar onSearch={onSearch} initialValue={search} />
        </div>
      </div>
    </CustomScrollbar>
  );
};

export default ResourceSearch;

import './ErrorLayout.css'

import PropTypes from "prop-types";
import React from "react";

import iconError from "../assets/e404.svg";
import ImageFluid from "../components/image/ImageFluid.js";
import RegularLayout from "./RegularLayout.js";

const ErrorLayout = (props) => (
    <RegularLayout title={props.title}>
        <section className="error-page">
            <h2 className="error-page-title">{props.title}</h2>
            <p className="error-page-subtitle">{props.subtitle}</p>
            <ImageFluid src={iconError} alt="Image error" className="error-page-image"/>
            {props.children}
        </section>
    </RegularLayout>
)

ErrorLayout.propTypes = {
    title: PropTypes.string.isRequired
}

export default ErrorLayout;
import React from "react";
import { Route } from "react-router-dom";

import Exercises from "../exercises/Exercises.js";
import LeafletList from "../leaflets//LeafletList.js";
import lazyImport from "../lib/LazyImport.js";

import ResourceListPage from "../pages/Resources/ResourceListPage.js"; // css order, cba to fix this right

import AppSkeleton from "./AppSkeleton.js";
// const FavoritesPage = lazyImport(() => import("../testing/Favorites"))
const Home = lazyImport(() => import("../pages/Home.js"));
const MainNewsArticle = lazyImport(() => import("../pages/MainNewsArticle.js"));
const CertificatePage = lazyImport(() => import("../pages/CertificatePage.js"));
const Survey = lazyImport(() => import("../pages/Survey.js"));

const Events = lazyImport(() => import("../pages/Event/Events.js"));
const EventHome = lazyImport(() => import("../pages/Event/EventHome.js"));
const EventSponsors = lazyImport(() =>
  import("../pages/Event/EventSponsors.js")
);
const EventQuizzes = lazyImport(() => import("../pages/Event/EventQuizzes.js"));
const EventQuiz = lazyImport(() => import("../pages/Event/EventQuiz.js"));

const Courses = lazyImport(() => import("../pages/Course/Courses.js"));
const CourseHome = lazyImport(() => import("../pages/Course/CourseHome.js"));
const CourseLearning = lazyImport(() =>
  import("../pages/Course/CourseLearning.js")
);
const CourseSponsors = lazyImport(() =>
  import("../pages/Course/CourseSponsors.js")
);
const CourseQuizzes = lazyImport(() =>
  import("../pages/Course/CourseQuizzes.js")
);
const CourseQuiz = lazyImport(() => import("../pages/Course/CourseQuiz.js"));

const PartnerHome = lazyImport(() => import("../pages/Partner/PartnerHome.js"));

const Trainings = lazyImport(() => import("../pages/Training/Trainings.js"));
const TrainingLearning = lazyImport(() =>
  import("../pages/Training/TrainingLearning.js")
);
const TrainingHome = lazyImport(() =>
  import("../pages/Training/TrainingHome.js")
);

const SponsorMain = lazyImport(() => import("../pages/Sponsor/SponsorMain.js"));
const SponsorHome = lazyImport(() => import("../pages/Sponsor/SponsorHome.js"));
const SponsorNews = lazyImport(() => import("../pages/Sponsor/SponsorNews.js"));
const SponsorInfographs = lazyImport(() =>
  import("../pages/Sponsor/SponsorInfographs.js")
);
const SponsorVideos = lazyImport(() =>
  import("../pages/Sponsor/SponsorVideos.js")
);
const SponsorStudies = lazyImport(() =>
  import("../pages/Sponsor/SponsorStudies.js")
);
const SponsorLinks = lazyImport(() =>
  import("../pages/Sponsor/SponsorLinks.js")
);
const SponsorStandArticles = lazyImport(() =>
  import("../pages/Sponsor/SponsorStandArticles.js")
);
const SponsorDocuments = lazyImport(() =>
  import("../pages/Sponsor/SponsorDocuments.js")
);
const SponsorNewsArticle = lazyImport(() =>
  import("../pages/Sponsor/SponsorNewsArticle.js")
);
const SponsorStudy = lazyImport(() =>
  import("../pages/Sponsor/SponsorStudy.js")
);
const SponsorInfograph = lazyImport(() =>
  import("../pages/Sponsor/SponsorInfograph.js")
);

const Resources = lazyImport(() => import("../pages/Resources/Resources.js"));
const Calculator = lazyImport(() => import("../pages/Resources/Calculator.js"));
const DecisionTree = lazyImport(() =>
  import("../pages/Resources/DecisionTree.js")
);
const Guideline = lazyImport(() => import("../pages/Resources/Guideline.js"));
const InfographResource = lazyImport(() =>
  import("../pages/Resources/InfographResource.js")
);
//const ResourceListPage = lazyImport(() => import("../pages/Resources/ResourceListPage.js"));
const ExerciseList = lazyImport(() => import("../exercises/ExerciseList.js"));
const ExercisesToPdf = lazyImport(() =>
  import("../exercises/ExercisesToPdf.js")
);

const ShoppingCart = lazyImport(() => import("../pages/Shop/ShoppingCart.js"));
const FinalizePurchase = lazyImport(() =>
  import("../pages/Shop/FinalizePurchase.js")
);

const TermsOfService = lazyImport(() =>
  import("../pages/Texts/TermsOfService.js")
);
const PrivacyPolicy = lazyImport(() =>
  import("../pages/Texts/PrivacyPolicy.js")
);
const FAQ = lazyImport(() => import("../pages/Texts/FAQ.js"));
const Contact = lazyImport(() => import("../pages/Texts/Contact.js"));
const ContactPartner = lazyImport(() =>
  import("../pages/Texts/ContactPartner.js")
);

const Register = lazyImport(() => import("../pages/Auth/Register.js"));
const Login = lazyImport(() => import("../pages/Auth/Login.js"));
const ProfileEdit = lazyImport(() => import("../pages/Auth/ProfileEdit.js"));
const ProfileItems = lazyImport(() => import("../pages/Auth/ProfileItems.js"));
const ForgotPassword = lazyImport(() =>
  import("../pages/Auth/ForgotPassword.js")
);
const ResetPassword = lazyImport(() =>
  import("../pages/Auth/ResetPassword.js")
);
const Error404 = lazyImport(() => import("../pages/Error/404.js"));
const Error403 = lazyImport(() => import("../pages/Error/403.js"));

//shop
const StoreMainPage = lazyImport(() => import("../store/StoreMainPage.js"));
const ProductPage = lazyImport(() => import("../store/ProductPage.js"));
const ValidateTicketDummy = lazyImport(() =>
  import("../pages/Admin/Events/ValidateTicketDummy.js")
);

// separar routes em ficheiros n dá, a nao ser q tenhas uma key ou q faças de uma maneira jiga joga
// https://stackoverflow.com/questions/43026690/declaring-react-routes-in-a-separate-file-and-importing

const PublicApp = (props) => (
  <AppSkeleton>
    <Route path="/" element={<Home />} />

    <Route path="/store" element={<StoreMainPage />} />
    <Route path="/store/products/:productId" element={<ProductPage />} />

    <Route
      path="/news/:articleName/:articleId"
      element={<MainNewsArticle />}
    />

    <Route path="/events" element={<Events />} />
    <Route path="/events/:eventName/:eventId" element={<EventHome />} />
    <Route path="/events/:eventId" element={<EventHome />} />
    <Route path="/events/:eventId/sponsors" element={<EventSponsors />} />
    <Route path="/events/:eventId/quizzes" element={<EventQuizzes />} />
    <Route path="/events/:eventId/quizzes/:quizId" element={<EventQuiz />} />
    <Route path="/events/:eventId/certificate" element={<CertificatePage />} />
    <Route path="/events/:eventId/survey" element={<Survey />} />

    <Route path="/courses" element={<Courses />} />
    <Route path="/courses/:courseName/:courseId" element={<CourseHome />} />
    <Route path="/courses/:courseId" element={<CourseHome />} />
    <Route path="/courses/:courseId/learning" element={<CourseLearning />} />
    <Route path="/courses/:courseId/sponsors" element={<CourseSponsors />} />
    <Route path="/courses/:courseId/quizzes" element={<CourseQuizzes />} />
    <Route path="/courses/:courseId/quizzes/:quizId" element={<CourseQuiz />} />
    <Route
      path="/courses/:courseId/certificate"
      element={<CertificatePage />}
    />
    <Route path="/courses/:courseId/survey" element={<Survey />} />

    <Route path="/trainings" element={<Trainings />} />
    <Route
      path="/trainings/:trainingId/:sessionIndex/:sessionItem/lecture"
      element={<TrainingLearning />}
    />
    <Route path="/trainings/:trainingId" element={<TrainingHome />} />
    <Route path="/trainings/:trainingId/survey" element={<Survey />} />
    <Route
      path="/trainings/:trainingId/certificate"
      element={<CertificatePage />}
    />

    <Route path="/sponsors/stands/:standId" element={<SponsorMain />} />
    <Route path="/sponsors/stands/:standId/home" element={<SponsorHome />} />
    <Route path="/sponsors/stands/:standId/news" element={<SponsorNews />} />
    <Route
      path="/sponsors/stands/:standId/infos"
      element={<SponsorInfographs />}
    />
    <Route
      path="/sponsors/stands/:standId/videos"
      element={<SponsorVideos />}
    />
    <Route
      path="/sponsors/stands/:standId/studies"
      element={<SponsorStudies />}
    />
    <Route path="/sponsors/stands/:standId/links" element={<SponsorLinks />} />
    <Route
      path="/sponsors/stands/:standId/documents"
      element={<SponsorDocuments />}
    />
    <Route
      path="/sponsors/stands/:standId/standarticles"
      element={<SponsorStandArticles />}
    />
    <Route
      path="/sponsors/stands/:standId/news/:articleId"
      element={<SponsorNewsArticle />}
    />
    <Route
      path="/sponsors/stands/:standId/infos/:infoId"
      element={<SponsorInfograph />}
    />
    <Route
      path="/sponsors/stands/:standId/studies/:studyId"
      element={<SponsorStudy />}
    />

    <Route path="/resources" element={<Resources />} />
    <Route
      path="/resources/calculators/:resourceName/:resourceId"
      element={<Calculator />}
    />
    <Route
      path="/resources/tables/:resourceName/:resourceId"
      element={<Calculator />}
    />
    <Route
      path="/resources/trees/:resourceName/:resourceId"
      element={<DecisionTree />}
    />
    <Route
      path="/resources/guidelines/:resourceName/:resourceId"
      element={<Guideline />}
    />
    <Route
      path="/resources/infos/:resourceName/:resourceId"
      element={<InfographResource />}
    />
    <Route path="/resources/:resourceType" element={<ResourceListPage />} />
    <Route path="/resources/leaflets" element={<LeafletList />} />
    <Route path="/resources/exercises" element={<Exercises />} />
    <Route path="/resources/exercises/:BodypartId" element={<ExerciseList />} />
    <Route
      path="/resources/exercises/:BodypartId/send/:stringToSend"
      element={<ExercisesToPdf />}
    />

    <Route path="/cart" element={<ShoppingCart />} />
    <Route path="/cart/confirmed/:method" element={<ShoppingCart />} />
    <Route path="/cart/finalize" element={<FinalizePurchase />} />

    <Route path="/terms" element={<TermsOfService />} />
    <Route path="/privacy" element={<PrivacyPolicy />} />
    <Route path="/faq" element={<FAQ />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/partner-contact" element={<ContactPartner />} />

    <Route path="/register" element={<Register />} />
    <Route path="/login" element={<Login />} />
    <Route path="/profile/details" element={<ProfileEdit />} />
    <Route path="/profile/items/:tab" element={<ProfileItems />} />

    <Route path="/partner/details" element={<PartnerHome />} />

    <Route path="/password/forgot" element={<ForgotPassword />} />
    <Route path="/password/reset/:token/:userId" element={<ResetPassword />} />

    <Route path="/events/validate/dummy" element={<ValidateTicketDummy />} />

    {props.children}
    <Route path="/404" element={<Error404 />} />
    <Route path="/403" element={<Error403 />} />
  </AppSkeleton>
);

//<Redirect to="/404" />
//TODO check route 404
export default PublicApp;

import axios from "axios";

import useAPIList from "./useAPIList.js";

export function useSpecialties() {
    return useAPIList("/api/specialties");
}

// --- Themes
export function fetchThemes(specialtyId) {
    return axios.get(`/api/specialties/${specialtyId}/themes`).then(res => res.data)
}

export function addTheme(specialtyId, body) {
    return axios.post(`/api/specialties/${specialtyId}/themes`, body).then(res => res.data)
}

export function fetchTheme(id) {
    return axios.get(`/api/specialties/themes/${id}`).then(res => res.data)
}

export function updateTheme(id, body) {
    return axios.put(`/api/specialties/themes/${id}`, body).then(res => res.data)
}

export function deleteTheme(id) {
    return axios.delete(`/api/specialties/themes/${id}`).then(res => res.data)
}


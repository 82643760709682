import classNames from 'classnames';
import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";


const RegularLink = (props) => {
    const {to, className, style, external, children, ...otherProps} = props;

    let linkStyle = Object.assign({
        textDecoration: "underline",
        color: "var(--primary)"
    }, style);

    if (external)
        return <a rel="noopener noreferrer" target="_blank" href={to} style={linkStyle} className={classNames("regular-link", className)}{...otherProps}>{children}</a>
    else return <Link to={to} style={linkStyle} className={classNames("regular-link", className)} {...otherProps}>{children}</Link>
}

RegularLink.propTypes = {
    external: PropTypes.bool,
    to: PropTypes.string.isRequired
}

RegularLink.defaultProps = {
    external: false
}

export default RegularLink;
import {
  Star as StarIcon,
  StarBorder as StarBorderIcon,
} from "@mui/icons-material";

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { favoriteResource, unfavoriteResource } from "../api/ResourceAPI.js";
import { fetchStand } from "../api/SponsorAPI.js";
import ButtonPrimary from "../components/buttons/ButtonPrimary.js";
import ShadowCard from "../components/card/ShadowCard.js";
import useBool from "../hooks/useBool.js";
import { startBrowserDownload } from "../lib/FileDownloader.js";
import Backdrop from "./Backdrop.js";
import Figure from "./Figure.js";
import styles from "./LeafletItem.module.css";
import Modal from "./Modal.js";
import SponsorButton from "./SponsorButton.js";

const ResourceList = ({ resources, resourceType }, props) => {
  // Guidelines?
  return (
    <section className={styles.resources}>
      {resources.map((resource, index) => (
        <ResourceListItem
          key={resource.id + "-" + index}
          resource={resource}
          resourceType={resourceType}
          deleteHandler={props.deleteHandler}
        />
      ))}
    </section>
  );
};

ResourceList.propTypes = {
  resources: PropTypes.array.isRequired,
  resourceType: PropTypes.string.isRequired,
};

const ResourceListItem = ({ resource, resourceType }, props) => {
  //const navigate = useNavigate();
  const [isFavorite, favorite, unfavorite] = useBool(resource.favorite);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  function deleteHandler() {
    setModalIsOpen(true);
  }
  function closeModalHandler() {
    setModalIsOpen(false);
  }
  const toggleFavorite = (event) => {
    event.stopPropagation();
    if (isFavorite) {
      unfavoriteResource(resource.id).catch(console.error);
      unfavorite();
    } else {
      favoriteResource(resource.id).catch(console.error);
      favorite();
    }
  };

  const [stand, setStand] = useState();
  useEffect(() => {
    if (resource.stand_id) fetchStand(resource.stand_id).then(setStand);
  }, [resource.stand_id]);

  return (
    <div className={styles.card2}>
      <ShadowCard className={styles.resource}>
        <header className={styles.header}>
          <div>
            <Figure src={resource.Leaflet.preview} />
          </div>
          <div className={styles.rightSide}>
            <h3 className={styles.title}>{resource.title}</h3>
            <small className={styles.description}>{resource.description}</small>
            {stand && (
              <SponsorButton className={styles.SponsorButton} stand={stand} />
            )}
            <div className={styles.header}>
              <ButtonPrimary
                className={styles.downloadButton}
                onClick={deleteHandler}
                id={resource.id}
              >
                {" "}
                Enviar por Email{" "}
              </ButtonPrimary>{" "}
              <DownloadGuidelineButton
                resourceId={resource.id}
                name={resource.name}
              />
            </div>
          </div>
        </header>

        {isFavorite ? (
          <StarIcon
            className={classNames(styles.favorite, styles.selected)}
            onClick={toggleFavorite}
          />
        ) : (
          <StarBorderIcon
            className={styles.favorite}
            onClick={toggleFavorite}
          />
        )}
      </ShadowCard>
      {modalIsOpen ? (
        <Modal
          onCancel={closeModalHandler}
          onConfirm={closeModalHandler}
          pid={resource.id}
        />
      ) : null}
      {modalIsOpen ? <Backdrop onClick={closeModalHandler} /> : null}
    </div>
  );
};

const DownloadGuidelineButton = (props) => (
  <ButtonPrimary
    onClick={(event) => {
      event.stopPropagation();
      startBrowserDownload(
        `/api/resources/leaflets/${props.resourceId}/download`
      );
    }}
    className={styles.downloadButton}
  >
    Download
  </ButtonPrimary>
);

export default ResourceList;

import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

/* eslint import/no-named-as-default-member: 0 */

const GoogleAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_ANALYTICS_TRACKING) {
            console.log("GA Initialized!");
            ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING, {
                // debug: true,
            });

            // This effect will run every time the location changes
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [location]);

    return null;
}

GoogleAnalytics.recordEvent = (category, action) => {
    ReactGA.event({ category, action });
}

export default GoogleAnalytics;

import axios from "axios";

import AxiosFileSender from "../lib/AxiosFileSender.js";
import useAPIItem from "./useAPIItem.js";
import useAPIList from "./useAPIList.js";

export function asArticle(resource) {
    return {
        id: resource.id,
        title: resource.title,
        content: resource.description,
    }
}

export function calculatorAsForm(calculator) {
    return {
        ...calculator,
        inputs: JSON.stringify(calculator.inputs, null, 2),
        variables: JSON.stringify(calculator.variables, null, 2),
        results: JSON.stringify(calculator.results, null ,2),
    }
}

// --

export function useResourceCount(resourceType) {
    return useAPIItem(`/api/resources/${resourceType}/count`, 0)
}

export function useCalculatorDocuments(calculatorId) {
    return useAPIList(`/api/resources/calculators/${calculatorId}/documents`, `/api/resources/calculators/documents`);
}

export function useResourceThemes(resourceId) {
    return useAPIList(`/api/resources/${resourceId}/themes`)
}


export function fetchResourcesByFilter(resourceType, filter, startsWith, limit, offset) {
    return axios.get(`/api/resources/${resourceType}`, {
        params: { filter, startsWith, limit, offset }
    }).then(res => res.data)
}

export function fetchResourcesByTheme(resourceType, theme, limit, offset) {
    return axios.get(`/api/resources/${resourceType}`, {
        params: { theme, limit, offset }
    }).then(res => res.data);
}

export function fetchResourcesByQuery(resourceType, search, limit, offset) {
    return axios.get(`/api/resources/${resourceType}/search`, {
        params: { search, limit, offset }
    }).then(res => res.data);
}


export async function fetchResource(resourceType, resourceId) {
    if (resourceType === null)
        return axios.get(`/api/resources/${resourceId}`).then(res => res.data)

    const resource = await axios.get(`/api/resources/${resourceType}/${resourceId}`).then(res => res.data);

    resource.title = resource.Resource.title
    resource.description = resource.Resource.description

    if (resourceType === "calculators") {
        resource.documents = await axios.get(`/api/resources/calculators/${resource.id}/documents`).then(res => res.data);
    }

    return resource;
}

export function setResourceStand(resourceId, standId) {
    return axios.put(`/api/resources/${resourceId}/stands/set`, { standId }).then(res => res.data)
}

export function toggleResourceTheme(resourceId, themeId, enable) {
    if (enable)
        return axios.put(`/api/resources/${resourceId}/themes/${themeId}`).then(res => res.data)
    else return axios.delete(`/api/resources/${resourceId}/themes/${themeId}`).then(res => res.data)
}


export function favoriteResource(id) {
    return axios.put(`/api/resources/${id}/favorite`)
}

export function unfavoriteResource(id) {
    return axios.put(`/api/resources/${id}/unfavorite`)
}

export function addResource(resourceType, values) {
    return AxiosFileSender.post(`/api/resources/${resourceType}`, values).then(res => res.data);
}

export function updateResource(resourceType, resourceId, values) {
    return AxiosFileSender.put(`/api/resources/${resourceType}/${resourceId}`, values).then(res => res.data).catch(err => {
        if (resourceType !== "calculators")
            throw err;

        const data = err.response.data;
        const errors = [
            data.inputErrors ? "Erros nos inputs:\n - " + (data.inputErrors || []).join("\n - ") : "",
            data.variableErrors ? "Erros nas variáveis:\n - " + (data.variableErrors || []).join("\n - ") : "",
            data.resultErrors ? "Erros nos resultados:\n - " + (data.resultErrors || []).join("\n - ") : "",
        ].filter(item => item).join("\n");
        if (errors)
            throw errors;
        else throw err;
    })
}

export function deleteResource(resourceType, resourceId) {
    return axios.delete(`/api/resources/${resourceType}/${resourceId}`).then(res => res.data)
}

export function calculate(resourceId, values) {
    return axios.post( `/api/resources/calculators/${resourceId}/calculate`, values).then(res => res.data).catch(err => {
        if (err.response.data.errors)
            throw Error(`O valor inserido para o campo '${err.response.data.errors[0].label}' é invalido`)
    });
}

import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { checkUser } from "../../api/AuthAPI.js";

const AuthContext = React.createContext();

const AuthProvider = (props) => {
    const location = useLocation();
    const [user, setUser] = useState(undefined);

    const refreshUser = () => {
        checkUser().then(setUser).catch(console.error);
    };
    
    useEffect(() => {
        refreshUser();
    }, [location]);

    return (
        <AuthContext.Provider value={user}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};

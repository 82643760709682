import React from "react";

import ErrorLayout from "../../layouts/ErrorLayout.js";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error);
        console.log(errorInfo);
    }

    render() {
        if (this.state.error) {
            return <ErrorLayout title={"Erro"} subtitle={"Pedimos desculpa, mas ocorreu um erro inesperado."}/>
        }

        return this.props.children;
    }
}


export default ErrorBoundary;
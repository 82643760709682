import classNames from 'classnames';
import PropTypes from "prop-types";
import React from "react";

function makeErrorMessage(error) {
    if (!error)
        return "";
    if (typeof error === "string")
        return error;
    if (error.response && error.response.data) {
        error = error.response.data;
        if (typeof error.message === "string")
            return error.message;
        else if (Array.isArray(error.errors) && error.errors.length > 0)
            return `${error.errors[0].param}: ${error.errors[0].msg}`;
        else return "Ocorreu um erro: " + JSON.stringify(error);
    }
}

const FormKeyContext = React.createContext(null);

const Form = ({
    formKey,
    onSubmit,
    onChange = () => {}, // Default parameter
    style,
    className,
    children
}) => {
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formKey.validate())
            return;

        formKey.notifySubmitStart();
        onSubmit(formKey.getValues(), (error) => {
            formKey.notifySubmitFinish(makeErrorMessage(error));
        });
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;

        switch (target.type) {
            case "checkbox": return onChange(name, target.checked);
            case "file": return onChange(name, target.files);
            default: return onChange(name, target.value);
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            onChange={handleChange}
            style={style}
            className={classNames(className, "form-container")}
        >
            <FormKeyContext.Provider value={formKey}>
                {children}
            </FormKeyContext.Provider>
        </form>
    );
};

export default Form;

export { FormKeyContext };

Form.propTypes = {
    formKey: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    style: PropTypes.any,
    className: PropTypes.string,
};

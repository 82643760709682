import { Container } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import FooterContacts from "../components/navigation/FooterContacts.js";
import FooterNav from "../components/navigation/FooterNav.js";
import TopBar from "../components/navigation/TopBar.js";
import AppLayout from "./AppLayout.js";

const RegularLayout = ({ title, description, children, disableContainer }) => {
  return (
    <AppLayout title={title} description={description}>
      <TopBar />
      {disableContainer ? (
        <div style={{ flexGrow: 1, paddingBottom: "2em" }}>{children}</div>
      ) : (
        <Container
          style={{ flexGrow: 1, maxWidth: "1200px", paddingBottom: "2em" }}
        >
          {children}
        </Container>
      )}
      <FooterNav />
      <FooterContacts />
    </AppLayout>
  );
};

RegularLayout.propTypes = {
  title: PropTypes.string.isRequired,
};

export default RegularLayout;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  fetchResourcesByFilter,
  fetchResourcesByQuery,
  fetchResourcesByTheme,
} from "../../api/ResourceAPI.js";
import ResourceList from "../../components/adhara/resources/ResourceList.js";
import APIFetcher from "../../components/loading/APIFetcher.js";
import useHash from "../../hooks/useHash.js";
import useQuery from "../../hooks/useQuery.js";
import ResourceLayout from "../../layouts/ResourceLayout.js";

const ResourceListPage = () => {
  const navigate = useNavigate();
  const { resourceType } = useParams();

  // Handle invalid resource type using useEffect
  useEffect(() => {
    const validResourceTypes = [
      "calculators",
      "tables",
      "trees",
      "guidelines",
      "infos",
    ];
    if (!validResourceTypes.includes(resourceType)) {
      navigate("/404");
    }
  }, [resourceType, navigate]);

  const { query, letter, theme } = useQuery();
  const hash = useHash(); // specialty, all, popular, favorite

  const [resources, setResources] = useState([]);
  const [usePagination, setPagination] = useState(true);

  const fetchItems = (page) => {
    const limit = 20;
    const offset = page * limit;

    const callback = (resources) => {
      if (page === 0) setResources(resources);
      else setResources((prevResources) => prevResources.concat(resources));

      if (resources.length < limit) setPagination(false);
    };

    if (query)
      return fetchResourcesByQuery(resourceType, query, limit, offset).then(
        callback
      );
    if (hash === "specialty")
      return fetchResourcesByTheme(resourceType, theme, limit, offset).then(
        callback
      );
    return fetchResourcesByFilter(
      resourceType,
      hash,
      letter,
      limit,
      offset
    ).then(callback);
  };

  return (
    <ResourceLayout resourceType={resourceType}>
      <APIFetcher
        fetchItems={fetchItems}
        hasItems={resources.length > 0}
        reloadOnChange={[resourceType, hash, query, letter, theme]}
        enablePagination={usePagination}
      >
        <ResourceList resources={resources} resourceType={resourceType} />
      </APIFetcher>
    </ResourceLayout>
  );
};

export default ResourceListPage;

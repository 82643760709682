import React, {useRef, useState}  from 'react';
import ReCAPTCHA2 from "react-google-recaptcha"
import Form from "../components/forms/Form.js";
import FormInput from "../components/forms/FormInput.js";
import FormSubmit from "../components/forms/FormSubmit.js"
import useFormKey from "../components/forms/useFormKey.js";
import PrimaryText from "../components/other/PrimaryText.js";
import { validateEmail} from "../lib/Validators.js";
import styles from "./LeafletForm.module.css";
import{SendLeaflet} from './ResourceAPI.js';



function LeafletForm(props) {

  const formKey = useFormKey();
  //const titleInputRef = useRef();
  const [recaptchaMsg, setrecaptachaMsg] = useState(false);
  
  const reRef= useRef();
  
  const  onSubmit = async (values, done) => 
  {
    const token = await reRef.current.executeAsync();
    
    values.token = token
    
    SendLeaflet(props.pid, values).then(r => {
      if(r.message === "Email sucessful")
      {
        props.submit();
      }
      else
      {
        setrecaptachaMsg(true)
        reRef.current.reset()
        done()
      }
    });
  }   

  return (
    <Form formKey={formKey} onSubmit={onSubmit}>
      <PrimaryText> Envio de Folheto Médico</PrimaryText>
      <PrimaryText style={{ textAlign: "left", fontSize: "small" }}>
        {" "}
        Nome do Paciente
      </PrimaryText>
      <FormInput name="nomePaciente" placeholder="Nome do Paciente" />
      <PrimaryText style={{ textAlign: "left", fontSize: "small" }}>
        {" "} 
        E-mail
      </PrimaryText>
      <FormInput name="email" autoComplete="email" placeholder="E-mail do Paciente" validator={validateEmail}/>
      <PrimaryText style={{ textAlign: "left", fontSize: "small" }}>
        {" "} 
        Nome Clínico
      </PrimaryText>
      <FormInput name="nomeClinico" placeholder="Nome Clínico"/>
      <PrimaryText style={{ textAlign: "left", fontSize: "small" }}>
        {" "} 
        Corpo de Texto
      </PrimaryText>
      <FormInput name="observacoes"  placeholder="Corpo de Texto" />
        <div className={styles.centerdivresized}>
          <ReCAPTCHA2 className={styles.center}
            sitekey="6LcRGf4dAAAAANC2qL__0bJBDeYFqhz5hEi5YXut"
            ref={reRef}
            size="invisible"
          />
        </div>
      {recaptchaMsg && <p className={styles.error}>Ocorreu um erro no envio do email!</p>}
      <FormSubmit className={styles.center}>
        Enviar Folheto
      </FormSubmit>
    </Form>
    
  );
}

export default LeafletForm;

import React from "react";

const ShadowCard = ({
  style = {},
  children,
  width = "auto",
  height = "auto",
  blur = "12px",
  spread = "3px",
  borderRadius = "6px",
  ...otherProps
}) => {
  const divStyle = Object.assign(
    {
      height: height,
      width: width,
      borderRadius: borderRadius,
      WebkitBoxShadow: `0 0 ${blur} ${spread} rgba(192,192,192,0.35)`,
      MozBoxShadow: `0 0 ${blur} ${spread} rgba(192,192,192,0.35)`,
      boxShadow: `0 0 ${blur} ${spread} rgba(192,192,192,0.35)`,
    },
    style
  );

  return (
    <div style={divStyle} {...otherProps}>
      {children}
    </div>
  );
};

export default ShadowCard;

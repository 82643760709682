import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import classNames from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material/index.js";
import { useSpecialties } from "../../../api/SpecialtyAPI.js";
import ShadowCard from "../../card/ShadowCard.js";
import RegularHeading from "../../header/RegularHeading.js";
import ImageFluid from "../../image/ImageFluid.js";
import {
  AllIcon,
  CalculatorIcon,
  ConverterIcon,
  FavoriteIcon,
  GuidelineIcon,
  InfographIcon,
  PopularIcon,
  TreesIcon,
} from "../icons/ResourceIcons.js";
import ResourceTypeToName from "./ResourceTypeToName.js";
import styles from "./ResourcesSidebar.module.css";

const icons = {
  calculators: <CalculatorIcon />,
  trees: <TreesIcon />,
  tables: <ConverterIcon />,
  guidelines: <GuidelineIcon />,
  infos: <InfographIcon />,
};

const ResourcesSidebar = (props) => {
  const location = useLocation();
  const selectedTheme = new URLSearchParams(location.search).get("theme");
  const resourceType = props.resourceType;

  const [specialties] = useSpecialties();

  return (
    <>
      <RegularHeading className={styles.heading}>
        {icons[resourceType]}
        <ResourceTypeToName resourceType={resourceType} />
      </RegularHeading>

      <nav className={styles.sidebar}>
        <ul className={styles.mainNav}>
          {navbarItems.map((item) => (
            <li key={item.label}>
              <NavbarItem
                link={`/resources/${resourceType}#${item.hash}`}
                icon={item.icon}
                label={item.label}
                selected={
                  location.hash
                    ? location.hash.includes(item.hash)
                    : item.default
                }
              />
            </li>
          ))}
        </ul>
        <Accordion
          sx={{
            boxShadow: "none",
            borderRadius: "8px !important",
            ".MuiAccordionSummary-expandIconWrapper": {
              color: "var(--primary)",
            },
            background: "transparent",
            ":before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            aria-controls={`content`}
            id={`header`}
            expandIcon={<KeyboardArrowDownIcon />}
            sx={{
              ".MuiAccordionSummary-content": {
                color: "var(--primary)",
                margin: "0 !important",
                padding: 0,
              },
            }}
          >
            Especialidades
          </AccordionSummary>
          <AccordionDetails
            sx={{
              gap: "12px",
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            {specialties.map((specialty) => (
              <Accordion
                key={specialty.id}
                sx={{
                  boxShadow: "0 0 15px 0 rgba(192, 192, 192, 0.5)",
                  borderRadius: "8px !important",
                  ".MuiAccordionSummary-expandIconWrapper": {
                    color: "var(--primary)",
                  },
                  ":before": {
                    display: "none",
                  },
                }}
                disabled={specialty.Themes.length <= 0}
              >
                <AccordionSummary
                  aria-controls={`content-${specialty.id}`}
                  id={`header-${specialty.id}`}
                  expandIcon={<KeyboardArrowDownIcon />}
                >
                  <span
                    className={classNames(styles.specialty, {
                      [styles.selected]: specialty.Themes.some(
                        (theme) => String(theme.id) === selectedTheme
                      ),
                    })}
                  >
                    <img
                      loading="lazy"
                      src={specialty.icon}
                      alt={specialty.name}
                    />
                    <span>{specialty.name}</span>
                  </span>
                </AccordionSummary>
                <AccordionDetails className={styles.themes}>
                  <NavbarSpecialty
                    specialty={specialty}
                    resourceType={resourceType}
                    selectedTheme={selectedTheme}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      </nav>
    </>
  );
};

const NavbarSpecialty = ({ specialty, resourceType, selectedTheme }) => {
  return (
    <>
      {specialty.Themes.map((theme) => (
        <Link
          key={theme.id}
          to={`/resources/${resourceType}?theme=${theme.id}#specialty`}
          className={classNames(styles.specialtyLink, {
            [styles.selected]: String(theme.id) === selectedTheme,
          })}
        >
          {theme.name}
          {theme.Stand && (
            <ImageFluid
              src={theme.Stand.Sponsor.logo}
              alt={theme.name + " Sponsor"}
            />
          )}
        </Link>
      ))}
    </>
  );
};

const NavbarItem = ({ label, icon, link, selected }) => (
  <Link to={link}>
    <ShadowCard
      className={classNames(styles.navbarItem, { [styles.selected]: selected })}
    >
      {icon}
      <span>{label}</span>
    </ShadowCard>
  </Link>
);

const navbarItems = [
  { label: "Popular", hash: "popular", icon: <PopularIcon /> },
  { label: "Favoritos", hash: "favorite", icon: <FavoriteIcon /> },
  { label: "Todos", hash: "all", icon: <AllIcon />, default: true },
  // { label: "Novos", hash: "new", icon: newIcon}
];

export default ResourcesSidebar;

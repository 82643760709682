import axios from "axios";
import { useEffect, useState } from "react";

import DefaultPreview from "../assets/preview_.png";
import AxiosFileSender from "../lib/AxiosFileSender.js";
import cleanForURL from "../lib/CleanForURL.js";
import useAPIList from "./useAPIList.js";

export function asForm(course) {
  return {
    ...course,
    date: course.date.split("T")[0],
    endDate: course.endDate.split("T")[0],
  };
}

export function getURL(course) {
  return `/courses/${cleanForURL(course.name)}/${course.id}`;
}

export function asFigure(course) {
  const date = new Date(course.date);
  const monthName = date.toLocaleString("pt-PT", { month: "long" });
  const formattedDate =
    monthName.charAt(0).toUpperCase() +
    monthName.slice(1) +
    " " +
    date.getFullYear();

  return {
    id: course.id,
    image: course.image,
    title: course.name,
    location: course.location,
    date: formattedDate,
    link: getURL(course),
  };
}

export function asArticle(course) {
  return {
    id: course.id,
    title: course.name,
    thumbnail: course.image,
  };
}

// ---

export function useCourses() {
  return useAPIList("/api/courses");
}

export function useCourse(id, onlyCourse = false) {
  const [course, setCourse] = useState({
    id: id,
    name: "Loading...",
    image: DefaultPreview,
    program: [],
    date: new Date(),
    endDate: new Date(),
    loading: true,
  });

  const [stands, setStands] = useState([]);
  const [committee, setCommittee] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    fetchCourse(id).then(setCourse).catch(console.error);

    if (onlyCourse) return;
    fetchStands(id).then(setStands).catch(console.error);
    fetchSessions(id).then(setSessions).catch(console.error);
    fetchCommittee(id).then(setCommittee).catch(console.error);
    fetchPermissions(id).then(setPermissions).catch(console.error);
  }, [id, onlyCourse]);

  return [course, sessions, stands, committee, permissions];
}

export function usePermissions(id) {
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    fetchPermissions(id).then(setPermissions).catch(console.error);
  }, [id]);

  return [permissions];
}

export function fetchPermissions(id) {
  return axios.get(`/api/courses/${id}/permissions`).then((res) => {
    return res.data;
  });
}

export function fetchCourses() {
  return axios.get("/api/courses").then((res) => res.data);
}

export function fetchMyCourses() {
  return axios.get("/api/courses/my").then((res) => res.data);
}

export function fetchNextCourses() {
  return fetchCourses().then((courses) =>
    courses.filter((course) => {
      const limitDate = new Date();
      limitDate.setDate(limitDate.getDate() - 1);
      return new Date(course.endDate) > limitDate})
  );
}

export function addCourse(body) {
  return AxiosFileSender.post("/api/courses", body).then((res) => res.data);
}

export function fetchCourse(id) {
  return axios.get(`/api/courses/${id}`).then((res) => res.data);
}

export function updateCourse(id, body) {
  return AxiosFileSender.put(`/api/courses/${id}`, body).then(
    (res) => res.data
  );
}

export function deleteCourse(id) {
  return axios.delete(`/api/courses/${id}`).then((res) => res.data);
}

// Sessions
export function useSessions(courseId) {
  return useAPIList(`/api/courses/${courseId}/sessions`);
}

export function fetchSessions(courseId) {
  return axios.get(`/api/courses/${courseId}/sessions`).then((res) => res.data);
}

// Enrollments
export function enrollCourse(courseId) {
  return axios.post(`/api/courses/${courseId}/enrollment`);
}

export function useEnrollments(courseId) {
  return useAPIList(`/api/courses/${courseId}/enrollment`);
}

export function toggleCertificate(courseId, userId, enable) {
  return axios
    .put(`/api/courses/${courseId}/enrollment/${userId}/certificate`, {
      enable,
    })
    .then((res) => res.data);
}

// Specialties
export function useSpecialties(courseId) {
  const [items, , edit, deleteItem] = useAPIList(
    `/api/courses/${courseId}/specialties`
  );
  return [items, edit, deleteItem]; // edit is HTTP put with an id, so works as add
}

// Commitee
export function useCommittee(courseId) {
  const [items, , edit, deleteItem] = useAPIList(
    `/api/courses/${courseId}/committee`
  );
  return [items, edit, deleteItem]; // edit is HTTP put with an id, so works as add
}

export function fetchCommittee(courseId) {
  return axios
    .get(`/api/courses/${courseId}/committee`)
    .then((res) => res.data);
}

// Stands
export function useStands(courseId) {
  const [items, , edit, deleteItem] = useAPIList(
    `/api/courses/${courseId}/stands`
  );
  return [items, edit, deleteItem]; // edit is HTTP put with an id, so works as add
}

export function fetchStands(courseId) {
  return axios.get(`/api/courses/${courseId}/stands`).then((res) => res.data);
}

export function fetchEnrollments(courseId, userId) {
  return axios
    .get(`/api/courses/${courseId}/enrollment/find`)
    .then((res) => res.data);
}

//Tags
export function useCourseTags(courseId) {
  const [items, , edit, deleteItem] = useAPIList(
    `/api/courses/${courseId}/tags`
  );
  return [items, edit, deleteItem]; // edit is HTTP put with an id, so works as add
}

//Target Audience
export function useCourseTargetAudience(courseId) {
  const [items, , edit, deleteItem] = useAPIList(
    `/api/courses/${courseId}/target-audience`
  );
  return [items, edit, deleteItem]; // edit is HTTP put with an id, so works as add
}

export function fetchCourseUsedTargetAudience() {
  return axios.get(`/api/courses/target-audience`).then((res) => res.data);
}

export function useCourseUsedTargetAudience() {
  const [targetAudience, setTargetAudience] = useState([]);

  useEffect(() => {
    fetchCourseUsedTargetAudience()
      .then((data) => {
        data.push({ id: 0, name: "Todas os cursos" });

        data.sort((a, b) => a.id - b.id);

        setTargetAudience(data);
      })

      .catch(console.error);
  }, []);

  return [targetAudience];
}